import React, { useContext, useEffect, useState, useRef } from "react"

import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import theme from "~theme/theme"

import { HeaderWrapper } from "~components/MainHeader"
import { MetadataContext } from "~components/layout"

const MobileHeader = () => {
  const metaData = useContext(MetadataContext)
  const [hideOnScroll, setHideOnScroll] = useState(true)
  const scrollPosition = useRef(0)

  useEffect(() => {
    const delta = 10
    const handleScroll = () => {
      const { scrollTop: currentScrollTop } = document.documentElement || document.body
      if (currentScrollTop <= 90) {
        setHideOnScroll(true)
      } else {
        if (Math.abs(scrollPosition.current - currentScrollTop) <= delta) return
        setHideOnScroll(scrollPosition.current > currentScrollTop)
        scrollPosition.current = currentScrollTop
      }
    }

    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () =>
      window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
      <>
        <MobileHeaderWrapper show={hideOnScroll}>
          <Flex>
            <Link to="/about">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"  style={{ height: '24px', transform: 'translateY(-2px)'}} viewBox="0 0 4.46 28.41">
                <path d="M0,0h4.46v4.07H0V0z M0.39,8.6H4.1v19.81H0.39V8.6z"/>
              </svg>
            </Link>
            <h1>
              <Link to="/">{metaData.site_title}</Link>
            </h1>
            <Link to="/search" style={{opacity: 0}}>
              <svg style={{ maxHeight: '18px', maxWidth: '22px' }} width="114" height="104" viewBox="0 0 114 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                  <path d="M51.8 15C60.8 15 69.5 18.4 76.3 24.4C83.1 30.5 87.3 38.9 88.2 48C89.1 57.1 86.6 66.2 81.1 73.5L77.9 77.8L73.6 81C68.3 85 62 87.4 55.3 88.1C54.1 88.2 52.8 88.3 51.6 88.3C42.6 88.3 33.9 85 27.1 78.9C20.3 72.8 16.1 64.4 15.2 55.3C14.2 45.6 17.1 36 23.3 28.4C29.5 20.8 38.3 16.1 48 15.2C49.3 15.1 50.6 15 51.8 15ZM51.8 0C50.1 0 48.4 0.1 46.6 0.3C18.2 3.1 -2.6 28.4 0.3 56.8C2.9 83.5 25.4 103.4 51.7 103.4C53.4 103.4 55.1 103.3 56.9 103.1C66.6 102.1 75.4 98.6 82.7 93.1L93.1 103.5L114.1 103.7L93.1 82.6C100.5 72.6 104.4 59.9 103.1 46.6C100.5 19.9 78 0 51.8 0Z" fill="black"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="113.9" height="103.7" fill="white"/>
                  </clipPath>
                </defs>
              </svg>


            </Link>
          </Flex>
        </MobileHeaderWrapper>
        <MobileHeaderWrapperClone>
          <h1>
            <Link to="/">{metaData.site_title}</Link>
          </h1>
        </MobileHeaderWrapperClone>
      </>
  )
}

const MobileHeaderWrapper = styled(HeaderWrapper)`
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 1;
  transition: transform 210ms ease;
  transform: translateY(${({show}) => show ? '0' : '-100%'});
  visibility: visible;
  padding: 0.6rem calc(1rem - 10px);
  height: auto;
  h1, a {
    font-size: 30px;
    position: relative;
    @media screen and (max-width: 385px) {
      font-size: 24px;
    }
  }
  a:hover{
    border-bottom: none;
  }
  a{
    padding:6px 10px;
    display: flex;
    align-items: center;
    justify-content-center;
  }
`
const MobileHeaderWrapperClone = styled(MobileHeaderWrapper)`
  position: static;
  visibility: hidden;
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default MobileHeader
