import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { withPreview } from 'gatsby-source-prismic-graphql';

import styled from "styled-components"
import { GlobalStyle } from "~theme/global"
import '~theme/fonts.css'
import useBreakpoint from '~utils/use-breakpoint'
import useSiteSettings from '~utils/useSiteSettings'

import theme from "~theme/theme"
import Header from "./MainHeader"
import MobileHeader from "./MainHeaderMobile"
import Acknowledgement from "./Acknowledgement"


export const MetadataContext = React.createContext({})

const Layout = ({ children }) => {
  const { sm } = useBreakpoint()
  const { acknowledgement, siteSubtitle, siteTitle } = useSiteSettings()

  const contextObj = {
    twitter_handle: 'citiesppllove',
    site_title: siteTitle,
    site_subtitle: siteSubtitle,
    acknowledgement_of_country: acknowledgement
  }

  return (
    <MetadataContext.Provider value={contextObj}>
      <GlobalStyle />
      <Acknowledgement />
      {sm ? <Header /> : <MobileHeader />}
      <Main>
        {children}
      </Main>
    </MetadataContext.Provider>
  )
}

// const query = graphql`
//   {
//     prismic {
//       allWebsites {
//         edges {
//           node {
//             twitter_handle
//             site_title
//             site_subtitle
//             acknowledgement_of_country
//           }
//         }
//       }
//     }
//   }
// `

const Main = styled.div`
  padding: 1rem;
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
