import { createGlobalStyle } from "styled-components"
import theme from "~theme/theme"

export const GlobalStyle = createGlobalStyle`
  :root {
    --highlight: hsl(243,100%,50%);
  }
  html {
    font-size: 18px;
    scroll-behavior: none;
    @media screen and (min-width: ${theme.breakpoints.sm}) {
      font-size: 13px;
    }
    @media screen and (min-width: ${theme.breakpoints.md}) {
      font-size: 15px;
    }
    @media screen and (min-width: ${theme.breakpoints.lg}) {
      font-size: 17px;
    }
  }
  body {
    font-family: "Univers", sans-serif;
    margin: 0;
    color: black;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    &.info-page {
      background-color: ${theme.colours.highlight}
    }
  }
  h1, h2, h3, h4 {
    font-weight: normal;
    margin: 0;
    line-height: 1.2;
  }
  .x-small{
    font-size: ${theme.typeSizes.xsmall};
  }
  .small{
    font-size: ${theme.typeSizes.small};
  }
  .medium{
    font-size: ${theme.typeSizes.medium};
  }
  .large{
    font-size: ${theme.typeSizes.large};
  }
  .x-large{
    font-size: ${theme.typeSizes.xlarge};
  }
  p {
    margin: 0 0 0.5em;
    font-size: ${theme.typeSizes.small};
    line-height: 1.25;
  }
  ul{
    font-size: ${theme.typeSizes.small};
  }
  a {
    color: inherit;
    border-bottom: 1px solid;
    text-decoration: none;
    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
  input, textarea {
    font-family: inherit;
  }
  .underline {
    border-bottom: 1px solid;
    line-height: 1.4;
    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
`
