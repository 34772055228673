import styled from 'styled-components'
import theme from "~theme/theme"

import TextareaAutosize from 'react-autosize-textarea'

export const Input = styled.input`
  border: 0;
  border-bottom: 1px solid;
  padding: 5px 0;
  background: transparent;
  border-radius: 0;
  margin-bottom: 20px;
  font-size: ${theme.typeSizes.small};
  outline: 0;
  width: 100%;
  font-family: inherit;
  border-color: ${ ({ error }) => error ? theme.colours.red : 'inherit'  };
  &::placeholder {
    color: ${ ({ error }) => error ? theme.colours.red : theme.colours.black  };
    opacity: 1;
  }
  &:focus {
    border-bottom: 1px dashed;
  }
`
export const Label = styled.label`
  color: ${ ({ error }) => error ? theme.colours.red : 'inherit'  };
  font-size: ${theme.typeSizes.small};
`

export const TextareaWrapper = styled.div`
  min-height: 240px;
  display: flex;
  cursor: text;
`

export const Textarea = styled(TextareaAutosize)`
  background: transparent;
  border: 0;
  border-bottom: 1px solid;
  border-color: ${ ({ error }) => error ? theme.colours.red : 'inherit'  };
  border-radius: 0;
  color: black;
  font: inherit;
  font-size: ${theme.typeSizes.small};
  margin-top: auto;
  margin-bottom: 20px;
  outline: 0;
  padding: 5px 0;
  resize: none;
  width: 100%;
  &::placeholder {
    color: ${ ({ error }) => error ? theme.colours.red : theme.colours.black };
  }
  &:focus {
    border-bottom: 1px dashed;
  }

`
export const FormButton = styled.button`
  background: transparent;
  border: 0;
  color: black;
  cursor: pointer;
  font: inherit;
  font-size: ${theme.typeSizes.small};
  line-height: 1;
  padding: 0;
  text-transform: uppercase;
  &:hover {
  }
  &::placeholder {
    color: black;
  }
`

export const CloseIcon  = styled.div`
  cursor: pointer;
  position: absolute;
  padding: 10px;
  bottom: 0px;
  right: 0px;
  line-height: 0.75;
  font-size: 20px;
`

export const ArticleWrapper = styled.div`
  display: flex;
  // margin-top: calc(-1rem - 1px);
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: -1rem;
  box-sizing: border-box;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    margin-top: 0;
  }
`

export const ArticleList = styled.div`
  width: 100%;
  @media screen and (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.typeSizes.xlarge};
    top: calc(0.5em + 2rem);
    position: sticky;
    width: 33.333vw;
    max-height: 100vh;
    overflow-y: auto;
  }
`

export const MenuPageWrapper = styled.div`
  position: relative;
  background-color: ${theme.colours.highlight};
  padding: 27px 1rem;
  margin-top: -1rem;
  margin-right: -1rem;
  margin-bottom: -1rem;
  margin-left: -1rem;
  box-sizing: border-box;
  @media screen and (min-width: ${theme.breakpoints.sm}) {
    display: flex;
  }
`

export const MenuPageContent = styled.div`
  flex: 2;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    margin-top: 20px;
  }
`

export const MenuColumns = styled.div`
  margin-bottom: 20px;
  display: flex;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    display: block;
  }
  > div {
    flex: 1;
    column-gap: 2rem;
    @media screen and (min-width: ${theme.breakpoints.sm}) {
      &:first-child{
        margin-right: 1rem;
      }
      &:last-child{
        margin-left: 2rem;
      }
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      margin-left: 0;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
    }
    p {
      font-size: ${theme.typeSizes.small};
    }
  }
`

export const MenuImage = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  mix-blend-mode: multiply;
  filter: grayscale(1);
  max-width: 100%;
  &:before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`

export const Person = styled.div`
  margin-bottom: 20px;
  @media screen and (min-width: ${theme.breakpoints.sm}) {
    display: flex;
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
  }

  > div {
    display: flex;
    flex: 1;
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column-reverse;
    }
    > * {
      flex: 1;
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }
`

export const PersonImage = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  mix-blend-mode: multiply;
  filter: grayscale(1);
  max-width: 200px;
  align-self: flex-start;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    align-self: auto;
  }
  &:before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`
