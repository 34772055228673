import React, { useState, useEffect, useContext } from "react"
import { document } from "browser-monads"
import { MetadataContext } from "~components/layout"

import styled from "styled-components"
import theme from "~theme/theme"

import { CloseIcon } from "~theme/common"
import { RichText } from 'prismic-reactjs'

const AcknowledgementComponent = ({ data, location }) => {
  const metaData = useContext(MetadataContext)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(document.cookie.indexOf('acknowledgement_read') < 0)
  },[document])

  useEffect(() => {
    if(window.location.host.includes('gtsb.io')){
      setOpen(false)
    }
  }, [])

  const closeAcknowledgement = () => {
    setOpen(false)
    document.cookie = 'acknowledgement_read=true;'
  }

  if (!open) return null
  return (
    <AcknowledgementWrapper onClick={closeAcknowledgement}>
      <Acknowledgement>
        <p>
          {metaData.acknowledgement_of_country}
        </p>
        <CloseIcon onClick={closeAcknowledgement}>
          &times;
        </CloseIcon>
      </Acknowledgement>
    </AcknowledgementWrapper>
  )
}

const AcknowledgementWrapper  = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  .info-page & {
    display: none;
  }
`
const Acknowledgement  = styled.div`
  background-color: ${theme.colours.highlight};
  max-width: 480px;
  padding: 20px 24px;
  position: relative;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    box-sizing: border-box;
    position: fixed;
    top: 70px;
    left: 0;
    bottom: 0px;
    right: 0;
  }
`

export default AcknowledgementComponent
