import { useState, useLayoutEffect } from 'react'
import theme from '~theme/theme'

const getBreakpoints = (width) => {
  return {
    default: true,
    sm: width >= parseInt(theme.breakpoints.sm),
    md: width >= parseInt(theme.breakpoints.md),
    lg: width >= parseInt(theme.breakpoints.lg),
  }
}

const useBreakpoint = () => {
  const [breakpoints, setBreakpoints] = useState(getBreakpoints(parseInt(theme.breakpoints.lg)))

  const calcBreakpoints = () => {
    setBreakpoints(getBreakpoints(window.innerWidth))
  }

  useLayoutEffect(() => {
    calcBreakpoints()
    window.addEventListener('resize', calcBreakpoints)
    return () => window.removeEventListener('resize', calcBreakpoints)
  }, [])

  return breakpoints
}

export default useBreakpoint

