const colours = {
  black: '#000',
  red: '#f00',
  white: '#fff',
  highlight: '#e0b641',
}

// const defaultSpacing = 13

const typeSizes = ['0.77rem', '0.92rem', '1.15rem', '1.35rem', '1.54rem', '3rem']

typeSizes.xsmall = typeSizes[0]
typeSizes.small = typeSizes[1]
typeSizes.smedium = typeSizes[2]
typeSizes.medium = typeSizes[3]
typeSizes.large = typeSizes[4]
typeSizes.xlarge = typeSizes[5]
//
const breakpoints = ['600px', '900px', '1200px']

breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
//
// const typeSizes = {
//   base: ['12px', '16px', '1.3vw', '1vw'],
//   medium: ['14px', '16px', '1.56vw', '1.2vw'],
//   large: ['28px', '28px', '3.25vw', '2.5vw']
// }
//
// const typeSize = size => {
//   return (
//     `
//     font-size: ${typeSizes[size][0]};
//     @media screen and (min-width: ${breakpoints.sm}) {
//       font-size: ${typeSizes[size][1]};
//     }
//     @media screen and (min-width: ${breakpoints.md}) {
//       font-size: ${typeSizes[size][2]};
//     }
//     @media screen and (min-width: ${breakpoints.lg}) {
//       font-size: ${typeSizes[size][3]};
//     }
//     `
//   )
// }


export default {
  breakpoints,
  colours,
  typeSizes
}
