import React, { useContext, useState, useLayoutEffect, useRef, useEffect } from "react"
import { window, document } from "browser-monads"

import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { vwpx } from '~utils/vwpx'
import theme from "~theme/theme"

import { MetadataContext } from "~components/layout"

const Header = () => {
  const metaData = useContext(MetadataContext)
  const [hovering, setHovering] = useState(false)

  const headerRef = useRef()
  const fixedHeaderRef = useRef()
  const [scale, setScale] = useState(1)
  const [renderedHeight, setRenderedHeight] = useState(0)
  const [articleTop, setArticleTop] = useState(999)
  let oldFromTop = 9999;

  useLayoutEffect(() => {
    const scrollOffset = e => {
      const currentArticle = document.querySelector('.current-article')
      if (!currentArticle || !headerRef.current) return
      const fromTop = currentArticle.getBoundingClientRect().top
      setArticleTop(fromTop)
    }
    scrollOffset()

    window.addEventListener("scroll", scrollOffset)
    return () => window.removeEventListener("scroll", scrollOffset)
  }, [])

  useEffect(() => {
    setRenderedHeight(headerRef.current.clientHeight)
  })

  return (
    <>
      <HeaderWrapperFixed scale={scale} height={articleTop} maxHeight={renderedHeight} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)} ref={fixedHeaderRef}>
         <div>
           <Nav height={articleTop} maxHeight={renderedHeight} className="medium">
             <Link to="/about">About</Link>
           </Nav>
           <h1 className="x-large">
             <Link to="/">
               {
                 hovering ?
                 metaData.site_subtitle
                 : metaData.site_title
               }
             </Link>
           </h1>
           <SecondaryNav height={articleTop} maxHeight={renderedHeight} className="medium">
             <Link to="/about?subscribe=true">Subscribe</Link>
           </SecondaryNav>
         </div>
       </HeaderWrapperFixed>
      <HeaderWrapper ref={headerRef} >
         <div>
           <h1 className="x-large">
             <Link to="/">
               {metaData.site_title}
             </Link>
           </h1>
         </div>
       </HeaderWrapper>
    </>
  )
}

export const HeaderWrapper = styled.div`
  border-bottom: 1px solid;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  visibility: hidden;
  background-color: ${theme.colours.white};
  font-size: ${theme.typeSizes.xlarge};
  height: calc(1em + 4rem);
  h1 {
    position: absolute;
  }
  a {
    border: 0;
    text-decoration: none;
  }
`
const HeaderWrapperFixed = styled(HeaderWrapper)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  visibility: visible;
  will-change: height;
  min-height: calc(0.5em + 2rem);
  max-height: calc(1em + 4rem);
  height: ${props => props.height > 1 ? props.height : 1}px;
  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    white-space: nobreak;
    will-change: transform;
    transition: transform 0.2s;
    backface-visibility: hidden;
    transform: translate(-50%, -50%) scale(${props => props.height + 1 < props.maxHeight ? '0.7' : '1'});
  }
`

const Nav = styled.div`
  position: absolute;
  left: 1rem;
  text-align: left;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.2s;
  z-index: 5;
  opacity: ${props => props.height + 1 < props.maxHeight ? '0' : '1'};
`

const SecondaryNav = styled(Nav)`
  left: auto;
  right: 1rem;
`

export default Header
