import { graphql, useStaticQuery } from "gatsby"

const useSiteSettings = () => {

  const siteSettings = useStaticQuery(graphql`
    query {
      sanitySiteSettings(_id: {regex: "/(drafts\\\\.)?siteSettings/"}) {
        _rawEditorial
        _rawAboutTextShort
        acknowledgement
        siteSubtitle
        siteTitle
      }
    }
    `)

  const settings = siteSettings.sanitySiteSettings

  return {
    editorial: settings._rawEditorial,
    acknowledgement: settings.acknowledgement,
    aboutTextShort: settings._rawAboutTextShort,
    siteSubtitle: settings.siteSubtitle,
    siteTitle: settings.siteTitle,
  }
}

export default useSiteSettings
